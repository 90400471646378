<template>
  <div class="shipment">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">
              {{ titlePage }}
            </div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="d-flex justify-content-center align-items-center form-group-edit"
                    >
                      <label for class="filter-date">De</label>
                      <b-form-datepicker
                        id="start-datepicker"
                        v-model="filter.created_after"
                        class="mb-2"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      class="d-flex justify-content-center align-items-center form-group-edit"
                    >
                      <label for class="filter-date">Até</label>
                      <b-form-datepicker
                        id="end-datepicker"
                        v-model="filter.created_before"
                        class="mb-2"
                      ></b-form-datepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Carteira</label>
                      <select v-model="filter.wallet_id" class="form-control">
                        <option value="" selected disabled hidden >Todas</option>
                        <option
                          v-for="item in createParams.wallets"
                          :value="item.id"
                          v-bind:key="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button
                      class="btn btn-primary btn-add float-right"
                      v-on:click="getClientsShipment()"
                    >
                      Filtrar
                    </button>
                    <button
                      class="btn btn-secondary clear-filter btn-add float-right"
                      v-on:click="clearFilter()"
                    >
                      Limpar Filtros
                    </button>
                  </div>
                  <div class="col-12">
                    <label> Clientes </label>

                    <multiselect
                      v-model="selectedClients"
                      track-by="name"
                      :multiple="true"
                      label="name"
                      placeholder="Selecione os Clientes"
                      :taggable="true"
                      :searchable="true"
                      :options="data"
                      :optionsLimit="1000"
                      ><span slot="noOptions">Sem dados</span>
                      <span slot="maxElements">Sem dados</span>
                    </multiselect>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary btn-add float-right button"
                      v-on:click="save()"
                    >
                      Salvar
                    </button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";
import { bus } from "@/main";
import axios from "@/api/axios";
import Swal from "sweetalert2";
import client_call from "@/imports/validator/models/client_call";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  name: "shipment",
  components: { Multiselect },
  data() {
    return {
      titlePage: "Nova Remessa",
      formType: "create",
      data: [],
      rows: 0,
      perPage: 1000,
      currentPage: 1,
      selectedClients: {},
      createParams: {
        wallets: [],
        clients: [],
      },
      file_url: [],
      filter: {
        created_after: "",
        created_before: "",
        wallet_id: "",
      },
      includes: ["wallets"],
      navigate_items: [
        {
          text: "Remessas",
          route: "dashboard.shipments",
        },
        {
          text: "Nova Remessa",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.filter.created_before = moment().format();
    this.filter.created_after = moment().subtract(7, "days").format();
    this.getCreateParams();
    this.getClientsShipment();
  },
  methods: {
    clearFilter:function(){
      this.filter.wallet_id = ""
    },
    getClientsShipment: function () {
      axios
        .post(`/api/getClients`, {
          created_after: this.filter.created_after,
          created_before: this.filter.created_before,
          wallet_id: this.filter.wallet_id,
        })
        .then((response) => {
          this.clients_shipments = response.data;
          console.log(this.clients_shipments)
          this.selectedClients = Object.assign([], this.clients_shipments);
        });
    },

    getCreateParams: function () {
      bus.$emit("changeLoading", true);
      helper.getCreateParams("clients").then((data) => {
        this.createParams = data.data;
        bus.$emit("changeLoading", false);
      });
    },

    save: function () {
      let data = {
        selectedClients: this.selectedClients,
      };
      axios
        .post(`/api/shipments`, data, {
          mode: "no-cors",
        })
        .then((response) => {
          this.getLink(response.data);
        });
    },
    filterTable: function () {
      this.filter.filter = this.filterTerm;
      this.currentPage = 1;
      this.providerData();
    },
    formatDate: function (date) {
      return helper.formatDateTime(date);
    },
    getLink: function (data) {
      axios
        .get(`/api/download-shipment/${data.id}`, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.download(url, data.name);
          window.URL.revokeObjectURL(url);
          Swal.fire({
            title: "Remessa cadastrada com sucesso",
            icon: "success",
            onClose: () => {
              this.$router.push({ name: "dashboard.shipments" });
            },
          });
        });
    },
    download: function (url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".txt");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "@/styles/general.scss";
.shipment {
  .select-cell {
    width: 20%;
    align-content: center;
  }
  .button {
    margin-top: 10px;
  }
  .clear-filter{
    margin-right:10px;

  }
  .filter-date {
    padding-right: 10px;
  }
}
</style>